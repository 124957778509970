<template>
  <div>
    <!-- 导航 -->
    <HomePageTop/>

    <div class="userCenter">
      <!-- 内容 -->
      <div class="userindexBody">
        <!--        左侧菜单及用户信息展示-->
        <div class="leftUserMessageContainer flexAndCenter">
          <!--用户信息展示 -->
          <div :class="className">
            <div class="userMessageContainerTop">
              <el-upload class="upload-demo" :action="headPortraitUploadUrl" :show-file-list="false"
                         :before-upload="beforeAvatarUpload" :headers="headers" :http-request="httpup"
                         :on-success="handleAvatarSuccess"
                         accept=".jpg,.jpeg,.png" name="file" style="margin-left:111px;">
                <div class="showDow">{{ $fanyi("更改头像") }}</div>
                <img v-if="userInfo.portrait_url !== ''" :src="userInfo.portrait_url" class="userHeadImg"/>
                <img v-else class="userHeadImg" src="../../assets/base-user.png"/>
                <img :src="userInfo.level.currentLevel.level_icon" class="levelIcon">
              </el-upload>
              <div class="userName">
                <div class="flexAndCenter" style="width:100%;flex-direction: column">
                  <div class="nameContainer" :title="userInfo.realname + ' 様'">{{ userInfo.realname }} 様</div>
                  <div class="fontSize12">(ID：{{ userInfo.operation_id }})</div>
                </div>
                <div class="levelMessageCenter flexAndCenter" style="justify-content: space-between">
                  <div class="nextLevelProcess" :style="'width:' + userInfo.level.nextLevelProcess + '%'"></div>
                  <el-popover trigger="hover" placement="top">
                    <div style="width: 200px">
                      <!--                      <div style="margin-bottom: 20px">当前累计发货金额：{{ userInfo.goods_amount }} 円</div>-->
                      <!--                      <div>-->
                      <!--                        您在RAKUMART的会员等级，我们将按照您的出货金额进行统计，当您提出配送单发货后，将给您累计发货金额，进度条展示您从当前等级到达下一等级的进度-->
                      <!--                      </div>-->
                      <div style="margin-bottom: 20px">現在の累積出荷金額は：{{ userInfo.goods_amount }} 円</div>
                      <div>
                        RAKUMARTの会員ランクは出荷金額に基づいて統計されます。配送指示書を出荷した後に累積出荷金額が加算されますので、予めご了承ください
                      </div>
                    </div>
                    <img src="../../assets/4.png" alt="" slot="reference"
                         style="width: 14px;height: 14px;cursor: pointer">
                  </el-popover>
                </div>
                <div class="fontSize14" style="margin-left: 20px">
                  {{ $fanyi("手续费") }}：
                  {{ $fun.returnServiceRate(userInfo.current_service_rate) }}%
                </div>
                <div
                    v-if="userInfo.level.currentLevel.level_name === '定額会員' || userInfo.level.currentLevel.level_name === 'SVIP'"
                    class="fontSize14" style="margin-top: 5px;margin-left: 20px">
                  {{ userInfo.level.currentLevel.level_name }}：{{
                    userInfo.level.currentLevel.end_date_format
                  }}&nbsp;{{ userInfo.level.currentLevel.end_time_format }}
                  ({{
                    $fanyi("会员到期")
                  }}{{ userInfo.level.currentLevel.expire_days }}{{ $fanyi("日") }})
                </div>
                <div v-if="userInfo.level.currentLevel.level_name === '手数料無料'" class="fontSize14"
                     style="margin-top: 5px;margin-left: 20px">
                  手数料無料：{{
                    userInfo.level.currentLevel.end_date_format
                  }}&nbsp;{{ userInfo.level.currentLevel.end_time_format }}({{
                    $fanyi("会员到期")
                  }}{{ userInfo.level.currentLevel.expire_days }}{{ $fanyi("日") }})
                </div>
              </div>
            </div>
          </div>
          <!-- 菜单栏 -->
          <div class="header">
            <div class="left">
              <div class="flexAndCenter balanceContainer">
                <div>
                  <div class="color000 fontSize18">{{ $fanyi("余额") }}</div>
                  <div class="userBalanceContainer flexAndCenter">
                    <div>{{ $fun.yenNumSegmentation(userInfo.balance) }}</div>
                    <div>{{ $fanyi("点") }}</div>
                  </div>
                </div>
                <div class="flex userBalanceBtnContainer">
                  <div v-if="$store.state.userInfo.operation_type !== 'childrenAccount'"
                       style="margin-right: 0" @click="$fun.routerToPage('/user/recharge')">{{ $fanyi("充值") }}
                  </div>
                </div>
              </div>
              <div class="flex userBottomPriceContainer">
                <div class="flex leftUserBottomPriceContainer">
                  <div v-if="userInfo.balance_freezing > 0">
                    <div class="fontSize14 color000">{{ $fanyi("不可使用") }}</div>
                    <div class="flexAndCenter">
                      <div class="fontWeightBold fontSize18 colorB4272B">
                        {{ $fun.yenNumSegmentation(userInfo.balance_freezing) }}
                      </div>
                      <div class="fontSize14 color000">{{ $fanyi("点") }}</div>
                    </div>
                  </div>
                  <div>
                    <div class="fontSize14 color000">{{ $fanyi("入金確認待ち金額") }}</div>
                    <div class="flex">
                      <div class="fontWeightBold fontSize18 colorB4272B">
                        {{ $fun.yenNumSegmentation($store.state.userInfo.balance_recharge_audit) }}
                      </div>
                      <div class="fontSize14 color000" style="margin-top: 2px">{{ $fanyi("点") }}</div>
                      <el-popover trigger="hover" placement="bottom">
                        <div style="width: 200px">
                          <!--                          待确认入金金额为您在系统里登记了银行汇款后，等待RAKUMART财务确认金额到账的金额-->
                          お客様が当サイトで送金操作した後着金確認されない金額になります。実際銀行振込を行い、弊社が着金確認した後に、入金額は残高へ反映されます。
                        </div>
                        <img src="../../assets/4.png" alt="" slot="reference">
                      </el-popover>
                    </div>
                  </div>
                  <div class="borderContainer"></div>
                </div>
                <div class="flex rightUserInfoContainer">
                  <div class="couponContainer flexAndCenter" @click="$fun.routerToPage('/user/coupon')">
                    <img src="../../assets/2.png" alt="">
                    <div>
                      <div class="titleText">{{ $fanyi("优惠券") }}</div>
                      <div class="flexAndCenter numContainer">
                        <div>{{ $store.state.userInfo.discount_count }}</div>
                        <div>{{ $fanyi("张") }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="informContainer flexAndCenter" @click="$fun.routerToPage('/user/inform')">
                    <transition v-if="system_message_unread_count > 0" name="el-fade-in-linear">
                      <img v-show="newIsShow" alt="" src="../../assets/3.png">
                    </transition>
                    <img v-else alt="" src="../../assets/3.png">
                    <div>
                      <div class="titleText">{{ $fanyi("通知") }}</div>
                      <div class="flexAndCenter numContainer">
                        <div>{{ system_message_unread_count }}</div>
                        <div>{{ $fanyi("条") }}（{{ $fanyi("未读") }}）</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="orderNavContainer flexAndCenter">
                <div class="orderNavLeftContainer">
                  <div>{{ ordersPayCount }}</div>
                  <div>{{ $fanyi("等待付款的订单") }}</div>
                </div>
                <div class="navText" @click="$fun.routerToPage('/user/order?status=obligation')">
                  {{ $fanyi("立即付款") }}>>
                </div>
              </div>
              <div class="orderNavContainer flexAndCenter">
                <div class="orderNavLeftContainer">
                  <div>{{ issueOrderValue }}</div>
                  <div>{{ $fanyi("等待处理的问题产品") }}</div>
                </div>
                <div class="navText" @click="$fun.routerToPage('/user/issueOrder')" style="margin-left: 17px">
                  <div>{{ $fanyi("前往处理") }}</div>
                  <div>>></div>
                </div>
              </div>
              <div class="orderNavContainer flexAndCenter">
                <div class="orderNavLeftContainer">
                  <div>{{ porderPayCount }}</div>
                  <div>{{ $fanyi("等待付款的国际运费") }}</div>
                </div>
                <div class="navText" @click="$fun.routerToPage('/user/deliveryList?status=obligation')">
                  {{ $fanyi("立即付款") }}>>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="contentBar">
          <div>
            <div class="titleBox newTitleBox" @click="$fun.routerToPage('/user/index')" style="cursor: pointer">
              <div class="flexAndCenterAndCenter">
                <img src="../../assets/base-user.png" style="width:40px;margin-right: 10px"
                     v-if="userInfo.is_pay_authorized == 1 || is_encasement_authorized == 1">
                <div>{{ $fanyi("首页") }}</div>
              </div>
            </div>
            <div class="menuBar">
              <div>
                <p class="titleBox" style="border-top-left-radius:10px;border-top-right-radius:10px;">
                  {{ $fanyi("订单管理") }}
                </p>
                <span :class="activePage === 'excelPlaceAnOrder' ? 'active' : ''"
                      @click="pageJump('/user/excelPlaceAnOrder')">
                  <span>Excel{{ $fanyi("下单") }}</span>
                </span>
                <span :class="activePage === 'userOrder' ? 'active' : ''" @click="pageJump('/user/order')">
                  <span> {{ $fanyi("全部订单") }}（{{ orderValue }}）</span>
                </span>
                <span :class="activePage === 'issueOrder' ? 'active' : ''" @click="pageJump('/user/issueOrder')">
                  <span> {{ $fanyi("问题商品") }}（{{ issueOrderValue }}）</span>
                </span>
                <span :class="activePage === 'wuliuStatus' ? 'active' : ''" @click="pageJump('/user/wuliuStatus')">
                  <span style="padding: 0"> {{ $fanyi("中国国内物流状态") }}（{{ logisticsSumValue }}）</span>
                </span>
                <span :class="activePage === 'warehouse' ? 'active' : ''" @click="pageJump('/warehouse')">
                  <span>{{ $fanyi("仓库") }}（{{ warehouseOrderNum }}）</span>
                </span>
                <span @click="pageJump('/user/deliveryList')" :class="activePage === 'deliveryList' ? 'active' : ''">
                  <span style="padding:0">{{ $fanyi("配送单") }}（{{ porderSumValue }}）</span>
                </span>
                <span v-if="newShow" :class="activePage === 'aftersale' ? 'active' : ''"
                      @click="pageJump('/user/aftersale')">
                      <span style="padding:0">{{ $fanyi("售后") }}</span>
                </span>
              </div>
              <div v-if="account_authority">
                <p class="titleBox">
                  {{ $fanyi("亚马逊仓库管理") }}
                </p>
                <span :class="activePage === 'amazonShopAuthorization' ? 'active' : ''"
                      @click="pageJump('/user/amazonShopAuthorization')">
                  <span>{{ $fanyi("店铺授权") }}</span>
                </span>
                <span :class="activePage === 'inventoryReport' ? 'active' : ''"
                      @click="pageJump('/user/inventoryReport')">
                  <span>{{ $fanyi("库存报告") }}</span>
                </span>
              </div>
              <div>
                <p class="titleBox">
                  {{ $fanyi("资金管理") }}
                </p>
                <span v-if="$store.state.userInfo.operation_type !== 'childrenAccount'"
                      :class="activePage === 'recharge' ? 'active' : ''" @click="pageJump('/user/recharge')">
                  <span>{{ $fanyi("充值") }}</span>
                </span>
                <span :class="activePage === 'churujinList' ? 'active' : ''" @click="pageJump('/user/churujinList')">
                  {{ $fanyi("账单") }}
                </span>
              </div>
              <div>
                <p class="titleBox">
                  {{
                    $fanyi("商品管理")
                  }}
                </p>
                <span @click="pageJump('/collection')" :class="activePage === '/collection' ? 'active' : ''"><span>{{
                    $fanyi("商品库")
                  }}（{{ inventoryNum }}）</span></span>
                <span @click="pageJump('/user/commodity')" :class="activePage === 'commodity' ? 'active' : ''"><span>{{
                    $fanyi("产品")
                  }}（{{
                    commodityNum
                  }}）</span>
                </span>
                <span :class="activePage === 'favoriteShop' ? 'active' : ''"
                      :style="operation_id==71?'border-bottom: 1px solid #D7D7D7;':''"
                      @click="pageJump('/user/favoriteShop')">店舗（{{
                    shopNum
                  }}）</span>
                <span v-if="operation_id==71"
                      :class="activePage === 'selfOrganizingSourceGoods' ? 'active' : ''"
                      @click="pageJump('/selfOrganizingSourceGoods')">
                  {{ $fanyi("自组货源") }}
                </span>
              </div>
              <div>
                <p class="titleBox">
                  {{ $fanyi("个人信息管理") }}
                </p>
                <span :class="activePage === 'userDetail' ? 'active' : ''" @click="pageJump('/user/userDetail')"><span>{{
                    $fanyi("注册信息修改")
                  }}</span></span>
                <span :class="activePage === 'deliverySite' ? 'active' : ''" @click="pageJump('/user/deliverySite')">
                  <span>{{ $fanyi("我的收货地址") }}</span></span>
                <span v-if="userInfo.operation_type === 'parentAccount'"
                      :class="activePage === 'EmployeesAccount' ? 'active' : ''"
                      @click="pageJump('/user/EmployeesAccount')"><span>{{ $fanyi("子账户") }}</span></span>
                <span v-if="$store.state.userInfo.operation_type !== 'childrenAccount'"
                      :class="activePage === 'userAuthorizeAndPermit' ? 'active' : ''"
                      @click="pageJump('/user/userAuthorizeAndPermit')">
                  <span style="border-bottom: none">{{ $fanyi("権限許可") }}</span></span>
              </div>
            </div>
          </div>
          <router-view style="width: 1109px;" @signStatusVerdict="listenSignStatus"></router-view>
        </div>
      </div>
    </div>
    <Foot/>
  </div>
</template>
<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot.vue";
import websiteUrl from "@/api/wangZhi";

export default {
  data() {
    return {
      warehouseOrderNum: 0,
      account_authority: false,
      commodityNum: 0,//收藏商品
      inventoryNum: 0,//商品库
      porderSumValue: 0,//配送单数量
      logisticsSumValue: 0,//中国国内物流
      issueOrderValue: 0,//问题产品数量
      orderValue: 0,//全部订单数量
      ordersPayCount: 0,//等待付款订单数量
      porderPayCount: 0,//等待付款订单数量
      aftersaleNum: 0,// 售后的订单数量
      newShow: true,
      className: 'defaultUserMessageHeaderContainer',
      headPortraitUploadUrl: websiteUrl + '/client/user.uploadPortraitImg',
      shopNum: 0,
      operation_id: '',
      goodsMessageUnread: null,
      newIsShow: true,
      system_message_unread_count: 0,
      is_encasement_authorized: 0
    };
  },
  components: {
    Foot,
    HomePageTop,
  },
  computed: {
    activePage() {
      return this.$route.name;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    headers() {
      return {
        "ClientToken": 'Bearer ' + localStorage.getItem('japan_user_token')
      }
    },
  },
  created() {
    this.ordersSum();
    this.logisticsSum();
    this.porderSum();
    this.getUserShopSum();
    if (location.host.indexOf('.co.jp') != -1 || location.host.indexOf('.com') != -1) {
      this.newShow = false;
    }
  },
  mounted() {
    this.$api.japanGetUserInfo().then((res) => {
      if (res.code != 0) {
        if (res.msg === '現在のログインは無効です，再度ログインしてください。') {
          this.$fun.routerToPage("/");
        }
        return
      }
      this.account_authority = res.data.account_authority;
      this.$store.commit("userData", res.data);
      this.system_message_unread_count = res.data.system_message_unread_count;
      this.is_encasement_authorized = res.data.is_encasement_authorized;
      if (res.data.system_message_unread_count > 0) {
        this.goodsMessageUnread = setInterval(() => {
          this.newIsShow = !this.newIsShow;
        }, 900)
      }
      this.operation_id = res.data.operation_id;
      switch (res.data.level.currentLevel.level_name) {
        case '定額会員':
          this.className = 'quotaMemberUserMessageHeaderContainer';
          break;
        case 'SVIP':
          this.className = 'sVipUserMessageHeaderContainer';
          break;
        case '手数料無料':
          this.className = 'freeCommissionUserMessageHeaderContainer'
          break;
      }
      this.warehouseSum();
    });
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("头像上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    // 图片上传事件
    httpup(file) {
      var _this = this;
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = function (e) {
        // console.log(e);
        // 创建图片元素
        let img = document.createElement("img");
        img.src = e ? e.target.result : "";
        img.onload = () => {
          let {width, height} = img;
          // 计算宽高比 ...
          // 创建canvas画布
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          //压缩尺寸 为方便这里直接/2
          // console.log(width, height);
          // 大小是由宽度决定的 300*300差不多是100到200kb
          //这里头像被裁剪后一定是正正方形所以写固定值,如果可以上传任意图片的时候不能使用固定值
          width = Math.max(100, width / 10);
          height = Math.max(100, height / 10);
          canvas.width = width;
          canvas.height = height;
          context?.clearRect(0, 0, width, height);
          context?.drawImage(img, 0, 0, width, height);
          // 转成blob
          canvas.toBlob(
              (blob) => {
                //图片压缩完成之后的操作 blob是压缩后blob格式的图片文件
                // console.log("size", blob?.size);
                // console.log("skdfh", blob); // 处理后的文件流BLOB
                // 以下为将文件加载到formData中以便文件上传
                let formData = new FormData();
                formData.append("file", blob, file.file.name);
                //文件上传请求
                var xhr = new XMLHttpRequest();
                let user_token = localStorage.getItem('japan_user_token')
                xhr.open("POST", _this.headPortraitUploadUrl, true);
                xhr.setRequestHeader("ClientToken", 'Bearer ' + user_token);
                xhr.onload = () => {
                  if (xhr.status === 200) {
                    const res = JSON.parse(xhr.responseText);
                    _this.handleAvatarSuccess(res);
                  } else {
                    console.log("An error occurred while uploading the file.");
                  }
                };
                xhr.send(formData);
              },
              file.type,
              0.5 //图片的质量
          );
          // 删除创建的图片元素
          img.remove();
        };
      };
    },
    handleAvatarSuccess(res, file) {
      this.$api.japanGetUserInfo().then((res) => {
        this.$store.commit("userData", res.data);
        this.loading.close();
        this.$forceUpdate();
      });
    },
    //获取订单列表各个状态数量
    ordersSum() {
      this.$api.ordersSum().then((res) => {
        if (res.code != 0) {
          let arr = [];
          this.$api.problemGoodsOrderNum().then((res) => {
            if (res.code != 0) return
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].name === '待回复') {
                arr.push(res.data[i]);
                this.issueOrderValue = res.data[i].count;
                this.$store.commit("setOrdersSumList", arr);
                break;
              }
            }
          });
        } else {
          res.data.forEach((item) => {
            switch (item.name) {
              case '等待付款':
                this.ordersPayCount = item.count;
                break;
              case '问题产品-待回复':
                this.issueOrderValue = item.count;
                break;
              case '全部':
                this.orderValue = item.count;
                break;
            }
          })
          this.$store.commit("setOrdersSumList", res.data);
        }
      })
    },
    listenSignStatus(msg) {
    },
    //获取中国国内物流各个状态的数量
    logisticsSum() {
      this.$api.chinaLogisticsListTableNum().then((res) => {
        if (res.code != 0) {
          return
        }
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].name === '全部') {
            this.logisticsSumValue = res.data[i].count;
            break;
          }
        }
        this.$store.commit("setChinaLogisticsListTableNumList", res.data);
      })
    },
    //获取配送单各个状态数量
    porderSum() {
      this.$api.storageDeliverNum().then((res) => {
        if (res.code != 0) {
          return
        }
        res.data.forEach((item) => {
          switch (item.name) {
            case '等待付款':
              this.porderPayCount = item.count;
              break;
            case '全部':
              this.porderSumValue = item.count;
              break;
          }
        })
        this.$store.commit("setStorageDeliverNumList", res.data);
      })
    },
    pageJump(val) {
      if (val === '/collection' || val === '/warehouse' || val === '/selfOrganizingSourceGoods') {
        window.open(val, "_blank");
      } else {
        this.$router.push(val)
      }
    },
    //获取仓库各个状态的数量
    warehouseSum() {
      this.$api.stockTableNum().then((res) => {
        if (res.code != 0) {
          return
        }
        res.data.forEach((item) => {
          switch (item.name) {
            case '普通仓库':
              this.warehouseOrderNum += item.count
              break;
            case '装箱中':
              if (this.is_encasement_authorized == 1) {
                this.warehouseOrderNum += item.count
              }
              break;
          }
        })
        this.$store.commit("setWarehouseSumList", res.data);
      })
    },
    //收藏商品
    getUserShopSum() {
      this.$api.getTableNumber().then((res) => {
        res.data.forEach((item) => {
          switch (item.favorite_group) {
            case 'favorite_goods':
              for (let i = 0; i < item.favorite_table.length; i++) {
                if (item.favorite_table[i].name === '全部') {
                  this.commodityNum = item.favorite_table[i].count;
                  break;
                }
              }
              break;
            case 'favorite_shop':
              this.shopNum = item.favorite_table[0].count;
              break;
            case 'favorite_inventory':
              for (let i = 0; i < item.favorite_table.length; i++) {
                if (item.favorite_table[i].name === '全部') {
                  this.inventoryNum = item.favorite_table[i].count;
                  break;
                }
              }
              break;
          }
        })
      })
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/mixin";

.index {
  margin: 0 auto;
}

.newTitleBox {
  width: 275px;
  height: 50px;
  background: #B4272B;
  border-radius: 10px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 10px;
}

.rowAndCenter {
  display: flex;
  align-items: center;
}

.userCenter {
  width: 1400px;
  margin: 0 auto 30px;

  //头部
  .userindexhead {
    width: 100%;
    height: 140px;
    margin: 20px 0;
  }

  //内容
  .userindexBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 320px;

    .leftUserMessageContainer {

      .header {
        display: flex;
        align-items: center;
        margin-left: 16px;
        margin-top: 20px;

        .left {
          width: 650px;
          height: 180px;
          background: #ffffff;
          box-sizing: border-box;
          padding: 30px;
          border-radius: 10px;
          margin-right: 16px;

          .balanceContainer {
            justify-content: space-between;
            padding-bottom: 15px;
            border-bottom: 1px solid #D7D7D7;

            .userBalanceContainer {

              div:first-child {
                color: #B4272B;
                font-weight: bold;
                font-size: 24px;
              }

              div:last-child {
                color: #000000;
                font-size: 14px;
              }
            }

            .userBalanceBtnContainer {
              div {
                width: 120px;
                height: 34px;
                background: #FFFFFF;
                border: 1px solid #B4272B;
                border-radius: 8px;
                text-align: center;
                line-height: 34px;
                cursor: pointer;
                color: #B4272B;
                font-size: 14px;
              }

              div:first-child {
                margin-right: 26px;
                color: #fff;
                background: #B4272B;
              }
            }
          }

          .userBottomPriceContainer {
            padding-top: 20px;

            .borderContainer {
              width: 1px;
              height: 22px;
              background: #D7D7D7;
              margin-top: 6px;
            }

            .leftUserBottomPriceContainer {
              width: 50%;
              justify-content: space-between;

              img {
                width: 20px;
                cursor: pointer;
                margin-left: 21px;
                margin-top: 2px;
              }
            }

            .rightUserInfoContainer {
              padding-left: 40px;
              justify-content: space-between;
              width: 50%;

              .couponContainer,
              .informContainer {
                cursor: pointer;

                img {
                  width: 40px;
                  height: 36px;
                  margin-right: 12px;
                }

                .titleText {
                  color: #000000;
                  font-size: 14px;
                  margin-bottom: 7px;
                }
              }

              .informContainer {
                img {
                  width: 30px;
                  height: 36px;
                  margin-right: 4px;
                }
              }

              .numContainer {
                div:first-child {
                  color: #B4272B;
                  font-weight: bold;
                  font-size: 18px;
                }

                div:last-child {
                  font-size: 14px;
                  color: #000000;
                  margin-left: 2px;
                }
              }
            }
          }
        }

        .right {
          width: 442px;
          height: 180px;
          background: #ffffff;
          border-radius: 10px;
          padding: 20px;

          .orderNavContainer {
            margin-bottom: 10px;

            .orderNavLeftContainer {
              background: #FAF2F2;
              height: 40px;
              width: 340px;
              min-width: 340px;
              border-radius: 20px;
              display: flex;
              align-items: center;

              div:first-child {
                border-radius: 20px;
                background: #B4272B;
                height: 40px;
                width: 70px;
                font-weight: bold;
                font-size: 18px;
                line-height: 40px;
                text-align: center;
                margin-right: 11px;
                color: #fff;
              }

              div:last-child {
                font-size: 14px;
                color: #000;
              }
            }

            .navText {
              color: #B4272B;
              text-decoration: underline;
              font-size: 14px;
              cursor: pointer;
              margin-left: 19px;
            }
          }
        }
      }

      .defaultUserMessageHeaderContainer, .freeCommissionUserMessageHeaderContainer, .sVipUserMessageHeaderContainer, .quotaMemberUserMessageHeaderContainer {
        width: 275px;
        height: 180px;
        border-radius: 10px;
        padding-top: 10px;
        margin-top: 20px;
        box-sizing: border-box;
        background: #ffffff;

        .userMessageContainerTop {
          display: flex;
          flex-direction: column;
          $userAvatar: 52px;
          position: relative;

          .upload-demo {
            position: relative;

            &:hover .showDow {
              opacity: 1;
            }

            .showDow {
              background-color: rgba($color: #000000, $alpha: 0.5);
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              opacity: 0;
              transition: 0.3s;
              top: 0;
              left: -40px;
              border-radius: 50%;
              width: $userAvatar;
              margin: 0 30px 0 40px;
              height: $userAvatar;
              color: white;
              font-size: 12px;
            }
          }

          .userHeadImg {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            margin-right: 16px;
          }

          .levelIcon {
            width: 23px;
            height: 23px;
            position: absolute;
            left: 35px;
            bottom: 0;
          }

          .userName {
            color: #333333;


            .top {
              font-size: 18px;
              padding: 0;
              display: flex;
              align-items: center;

              div:last-child {
                font-weight: bold;
              }
            }

            .nameContainer {
              font-size: 14px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            .levelMessageCenter {
              margin: 8px 0 8px 20px;
              width: 150px;
              height: 10px;
              border: 1px solid #B4272B;
              border-radius: 5px;
              position: relative;

              .nextLevelProcess {
                height: 100%;
                max-width: 150px;
                background: #B4272B;
                border-radius: 5px;
              }

              img {
                position: absolute;
                top: -2px;
                right: -30px;
                cursor: pointer;
                flex-shrink: 0;
              }
            }

            .bottom {
              font-size: 14px;
            }
          }
        }

        .userMessageContainerBottom {
          padding-top: 27px;
          margin-right: 30px;
          justify-content: space-between;
        }
      }

      .freeCommissionUserMessageHeaderContainer {
        background: linear-gradient(135deg, #FCFBFC, #CCE3EB, #C7E1E8) !important;

        .userName {
          color: #258190 !important;

          .levelMessageCenter {
            border: 1px solid #258190 !important;
          }

          .nextLevelProcess {
            background: #258190 !important
          }
        }
      }

      .sVipUserMessageHeaderContainer {
        background: linear-gradient(135deg, #F2F3F7, #ECE1FE, #D5E1FF) !important;

        .userName {
          color: #272157 !important;

          .levelMessageCenter {
            border: 1px solid #272157 !important;
          }

          .nextLevelProcess {
            background: #272157 !important
          }
        }
      }

      .quotaMemberUserMessageHeaderContainer {
        background: linear-gradient(135deg, #F9E4B7, #F5CD82, #F2D595) !important;

        .userName {
          color: #AB6B28 !important;

          .levelMessageCenter {
            border: 1px solid #AB6B28 !important;
          }

          .nextLevelProcess {
            background: #AB6B28 !important
          }
        }
      }
    }

    .contentBar {
      margin-top: 26px;
      display: flex;
      //background-color: white;

      .menuBar {
        width: 275px;
        min-width: 275px;
        margin-right: 16px;
        background: #ffffff;
        // 菜单内容左间距
        $fontMarginLeft: 30px;

        > div {
          p {
            width: 100%;
            height: 50px;
            font-size: 16px;
            line-height: 50px;
            font-weight: bold;
            text-align: center;
            box-sizing: border-box;

            &.titleBox {
              position: relative;
              background: #D7D7D7;

              img {
                position: absolute;
                left: 12px;
                top: 22px;
                width: 16px;
                height: 16px;

                &.sec {
                  display: none;
                }
              }

              &:hover {
                img {
                  &.sec {
                    display: inline-block !important;
                  }
                }
              }
            }
          }

          span,
          a {
            display: block;
            height: 50px;
            font-size: 13px;
            line-height: 50px;
            text-align: center;
            box-sizing: border-box;
            cursor: pointer;

            span {
              display: block;
              border-bottom: 1px solid #D7D7D7;
            }

            &.active {
              color: #fff;
              background-color: #B4272B;
            }

            &:hover {
              color: #fff;
              background-color: #B4272B;
            }
          }
        }
      }
    }
  }
}
</style>
